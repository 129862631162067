<template>
  <div class="cs_info">
    <ul>
      <li>
        <div class="title">{{ this.$t("cs.main_email") }}</div>
        <div class="value">info@weknowthem.com</div>
      </li>
      <li>
        <div class="title">{{ this.$t("cs.office_hour") }}</div>
        <div class="value">
          AM 10:00 KST ~ PM 17:00 KST<br />{{ this.$t("cs.off_days") }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("cs.lunch_hour") }}</div>
        <div class="value">PM 12:00 KST ~ PM 14:00 KST</div>
      </li>
      <li>
        <div class="title">{{ this.$t("cs.channeltalk") }}</div>
        <div class="value">
          <button
            class="button small compact sharp"
            @click="this.click_channeltalk"
          >
            {{ this.$t("cs.inquiry") }}
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ChannelService from "@/util/channelio.js";
export default {
  name: "CSInfo",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.cs"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.cs"),
      },
    };
  },
  methods: {
    click_channeltalk: function () {
      ChannelService.open();
    },
  },
};
</script>

<style scoped>
.cs_info > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  border-top: 1px solid #333;
}
.cs_info > ul > li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 1.4rem 1.6rem;
  border-bottom: 1px solid #dedede;
}
.cs_info > ul > li > .title {
  font-size: 1.1rem;
  font-weight: 700;

  width: 120px;
}
.cs_info > ul > li > .value {
  flex-grow: 1;
  font-size: 1.1rem;
  font-weight: 300;
}
</style>
